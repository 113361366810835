import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  let i = 0;
  const notify = () => {
    if (i === 0) {
      if (localStorage.getItem("showed-development-notice") !== "true") {
        toast.info("This site is still in development", {
          position: "top-center",
          autoClose: 3000,
          pauseOnHover: false,
          closeOnClick: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        i++;
        localStorage.setItem("showed-development-notice", "true");
      }
    }
  };
  return (
    <main>
      <div className="container" onLoad={notify}>
        <Header />
        <Outlet />
        <ToastContainer />
      </div>
    </main>
  );
};

export default Layout;
